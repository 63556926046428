// 가운데 정렬
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// 양쪽 정렬
@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// 중앙 정렬
@mixin position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// 상단 고정
@mixin position-fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
