.comingsoon_container {
  position: relative;
}
.logo {
  @include position-center;
  width: 30%;
}
.comingsoon {
  position: absolute;
  left: 50%;
  bottom: 30%;
  transform: translate(-50%, 30%);
  width: 30%;
}