:root {
  --mainEng-font: "Single Day";

  --main: #0098b0;
  --white: #fff;
  --black: #000;

  font-family: var(--mainEng-font), var(--mainKor-font);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 1.4;
  }
}

body {
  background-color: var(--mainBg-color);
}
