// border-box 초기화
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// 공백 초기화
body,
button,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
ol,
p,
select,
table,
td,
textarea,
th,
ul,
figure,
figcaption {
  margin: 0;
  padding: 0;
}

// 폰트 초기화
body,
button,
input,
select,
table,
textarea {
  font-family: var(--mainEng-font), var(--mainKor-font), "Apple SD Gothic Neo",
    "Malgun Gothic", "맑은 고딕", helvetica, sans-serif;
}

// 링크 초기화
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}

// 스타일 초기화
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul {
  list-style: none;
}

em,
address {
  font-style: normal;
}

strong {
  font-weight: normal;
}

img {
  vertical-align: top;
  width: 100%;
}
